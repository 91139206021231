<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="card card-custom">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-1"
            id="kt_wizard_v1"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Body-->
            <div class="row justify-content-center my-10 px-8 my-lg-10 px-lg-8">
              <div class="col-xl-12">
                <!--begin: Wizard Form-->
                <Form
                  :form="form"
                  route="letters"
                  purpose="add"
                  title="Tambah Surat Pengantar KTP"
                />
                <!--end: Wizard Form-->
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/view/components/cover-letter/Form.vue";
import { getConfiguration } from "@/core/services/jwt.service.js";

export default {
  components: {
    Form,
  },
  data() {
    return {
      form: {
        title: 'Surat Pengantar KTP',
        citizen_id: '',
        citizen_name: '',
        citizen_gender: '',
        citizen_id_card_number: '',
        citizen_birt_place: '',
        citizen_birt_date: '',
        citizen_citizenship: '',
        citizen_profession: '',
        citizen_is_married: '',
        citizen_is_married_display: '',
        citizen_address: '',
        opening_sentences: getConfiguration().letter_opening_sentences,
        main_sentences: '',
        closing_sentences: getConfiguration().letter_closing_sentences,
        letter_type_id: 9,
        resource: 1,
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Surat Menyurat", route: "/letters" },
      { title: "Pengantar KTP", route: "/cover-letter" },
      { title: "Tambah"},
    ]);
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 250px !important;
  height: 250px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>